export const articleRelations = [
    'ArticlePage001.crossSellCards',
    'ArticlePage001.whyTrustEnergySage',
    'ArticlePage001.fixedBannerCta',
    'ArticlePage001.fixedUpperBannerCta',
    'ArticlePage001.popupCta',
    'LocalArticlePage001.crossSellCards',
    'LocalArticlePage001.whyTrustEnergySage',
    'LocalArticlePage001.stickyZipCta',
    'ArticleCtaBanner.reference',
    'ArticleCtaLeafBanner.reference',
    'ArticleCtaBannerImage.reference',
    'ArticleEeat.authors',
    'ArticleEeat.editedBy',
    'ArticleEeat.socialLinks',
    'ArticleEeat.editorialGuidelines',
    'LocalArticleEeat.authors',
    'LocalArticleEeat.editedBy',
    'LocalArticleEeatV2.authors',
    'LocalArticleEeatV2.editedBy',
    'ArticleVerticalCta.reference',
    'ArticleRecommended.articles',
];

export const articleComponents = ['ArticlePage001', 'LocalArticlePage001'];
